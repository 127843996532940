<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8 mb-2">
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="info"
        chart-height="100"
        widgetTitle="Planned maintenance budget"
        widgetSubtitle="All assets"
        note=""
        chartValue="1 750 000 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="warning"
        chart-height="100"
        widgetTitle="Completed maintenance costs"
        widgetSubtitle="All assets"
        note=""
        chartValue="264 774 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="danger"
        chart-height="100"
        widgetTitle="Installed spare parts cost"
        widgetSubtitle="All assets"
        note=""
        chartValue="359 784 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="success"
        chart-height="100"
        widgetTitle="Remaining budget"
        widgetSubtitle="All assets"
        note=""
        chartValue="1 125 442 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
  </div>
  <div class="row gy-5 g-xl-8">
    <div class="col-3">
      <WidgetPercent
        widget-classes="mb-5 pb-10"
        chart-color="#980000"
        chart-height="400"
        widgetTitle="Total budget compliance"
        widgetSubtitle="All assets"
        note=""
        chartValue="10"
        fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-3">
      <!--
          gradientToColors: ["#f94144", "#f8961e", "#f9c74f", "#577590", "#277da1", "#43aa8b", "#90be6d"], -->
      <WidgetPercent
        widget-classes="mb-5 pb-10"
        chart-color="#ff9f1c"
        chart-height="400"
        widgetTitle="Maintenance budget compliance"
        widgetSubtitle="All assets"
        note=""
        chartValue="37"
        fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-3">
      <WidgetPercent
        widget-classes="mb-5 pb-10"
        chart-color="#980000"
        chart-height="400"
        widgetTitle="Spare parts budget compliance"
        widgetSubtitle="All assets"
        note=""
        chartValue="19"
        fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-3">
      <WidgetPercent
        widget-classes="mb-5 pb-10"
        chart-color="#8ac926"
        chart-height="400"
        widgetTitle="Remaining budget"
        widgetSubtitle="All assets"
        note=""
        chartValue="64"
        fontSize="35"
      ></WidgetPercent>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import WidgetPercent from "@/components/widgets/mixed/WidgetPercent.vue";
import WidgetNumber from "@/components/widgets/mixed/WidgetNumber.vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "maintenance-budgets-dashboard",
  components: {
    WidgetPercent,
    WidgetNumber
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Maintenance budgets");
      setCurrentPageBreadcrumbs("Maintenance", []);
      setCurrentPageToolbarType("maintenanceBudgets");
    });
  }
});
</script>
