
import { defineComponent, onMounted, onBeforeMount } from "vue";
import WidgetPercent from "@/components/widgets/mixed/WidgetPercent.vue";
import WidgetNumber from "@/components/widgets/mixed/WidgetNumber.vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "maintenance-budgets-dashboard",
  components: {
    WidgetPercent,
    WidgetNumber
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Maintenance budgets");
      setCurrentPageBreadcrumbs("Maintenance", []);
      setCurrentPageToolbarType("maintenanceBudgets");
    });
  }
});
